<template>
    <div class='page-index mind'>
        <div class="bcg-box">
            <bcg>
                <template v-slot:items>
                    <div class="index-module-item" 
                    @mousedown="$event.currentTarget.classList.add(!v.disabled ? 'button-scale-down' : 'd')"
                    @touchstart="$event.currentTarget.classList.add(!v.disabled ? 'button-scale-down' : 'd')"
                    @touchleave="$event.currentTarget.classList.remove(!v.disabled ? 'button-scale-down' : 'd')"
                    @mouseleave="$event.currentTarget.classList.remove(!v.disabled ? 'button-scale-down' : 'd')"
                    @touchend="$event.currentTarget.classList.remove(!v.disabled ? 'button-scale-down' : 'd')"
                    @mouseup="$event.currentTarget.classList.remove(!v.disabled ? 'button-scale-down' : 'd')"
                    v-for="(v, i) in moduleList" :key="i" :class="[v.class, {'disabled': v.disabled}]" @click="gotoPage(v)"></div>
                    <div class="avatar-box">
                        <avatarPet pageName="Home" :gif="true" :avatarPointer="true"
                        type="concise"
                        @click="_routeHand.goto({
                            name: 'user',
                            type: 'push',
                        })"></avatarPet>
                    </div>
                </template>
            </bcg>
            <animationCloud></animationCloud>
        </div>
        <div class="user-box">
            <div class="user-box-fx">
                <userInfo></userInfo>
                <div class="g71 flex-center cursor-pointer scale-button" @click="taskOpen">
                    <span class="text-small text bold">任务中心</span>
                    <div class="task-center-animation">
                        <div class="mind-new-task" v-if="newTask"></div>
                    </div>
                </div>
                <div class="mall-enter scale-button" v-show="!isMallOverdue" @click="gotoMall"></div>
                <trialCom ref="trialCom" v-show="showActivateFlag"></trialCom>
            </div>
        </div>
        <confirmPro ref="confirmPro" style="z-index: 300;">
            <div class="pro-close-box scale-button" slot="close" @click="quitApp"></div>
        </confirmPro>
    </div>
</template>
<script>
    import com from '@/views/index/index.vue'
    import apiMall from '@/API/page/mind/mall'
    import trialCom from './trialCom.vue'
    import courseApiClass from '@/API/page/task'
    import userInfo from '../../components/userInfo.vue'
    import TaskApi from '@/API/page/taskcenter.js'

    export default {
        name: '',
        extends: com,
        inject: {
            userPointStart: {
                from: 'userPointStart',
                default: '',
            },
        },
        props: {
        },
        data() {
            return {
                appData: new this.appDataClass({key: 'app'}),
                moduleList: [
                    {class: 'g66', routeName: 'cabin'},
                    {class: 'g67', routeName: 'task'},
                    {class: 'g68', routeName: 'palace'},
                    {class: 'g69', routeName: 'game', disabled: false},
                ],
                apiMall: new apiMall(),
                isMallOverdue: true,
                courseApi: new courseApiClass(),
                firstFlag: true,
                newTask: false,
                taskApi: new TaskApi(),
            };
        },
        methods: {
            mindPageInit(){
                this.courseListCovFun()
                this.mallOverdue()
            },
            async mallOverdue(){
                let res = await this.apiMall.mallOverdue()
                this.isMallOverdue = res
            },
            async courseListCovFun(){
                let res = await this.courseApi.courseListCov()
                if(!res || !res.length) return
                let back_flag = res.every(item => item.id > 2000) //所有课程id大于2000弹窗退出小镇
                if(back_flag) return this.backFun()
            },
            async backFun(){
                this.$refs.confirmPro.open({ message: '此功能未上线', confirmType: 'c1' })
            },
            quitApp(){
                this._common.emitEventBus('quit_app', {login: true})
            },
            gotoPage(val){
                val = val || {}
                let {routeName, disabled} = val
                // if(routeName == 'game') this.onlineToast()
                if(routeName == 'palace' && this.PARAMS.school_id != '10001') this.dashboardClick(9)
                if(disabled) return
                this._voiceHand.normalPlay('index button')
                this._routeHand.goto({
                    name: routeName,
                    type: 'push'
                })
            },
            async dashboardClick(click_type){
                await this.apiMall.postDashboardClick({ noLoad: true, click_type })
            },
            async gotoMall(){
                await this.dashboardClick(1)
                this._routeHand.goto({name: 'mall'})
            },
            onlineToast(){
                this._common.confirmPro.open({
                    message: '思维乐园还没有上线哦，敬请期待！',
                    className: 'balance-check',
                    confirmType: 'c10 balance-check',
                    buttons: [
                        {title: '确认', type: 'confirm'}
                    ]
                })
            },
            async pageInit(){
                this.imgInit()
                this.userAccount()
            },
            async userAccount(){
                let res = await this.api.userAccount()
                if(!res) return
                let {needActivateFlag} = res || {}
                if(needActivateFlag) return this._common.activateMember()
            },
            userPointSet(){
                if(this.firstFlag) {
                    this.userPointStart(this.firstFlag)
                    this.firstFlag = false
                }
            },
            async haveNewTask() {
                const res = await this.taskApi.haveNewTask()
                if (!res) {
                    this.newTask = false
                    return false
                }
                this.newTask = true
                return true
            }
        },
        created() {
            this.mindPageInit()
        },
        components: {
            trialCom,
            userInfo,
        },
        computed: {
            showActivateFlag(){
                let {app: {showActivateFlag = false}} = this.$store.state
                return showActivateFlag
            },
            activateRemaining(){
                let {app: {activateRemaining = null}} = this.$store.state
                return activateRemaining
            },
        },
        watch: {},
        mounted() {
            this.userPointSet()
            this.haveNewTask()
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less">
.page-index.mind{
    .com-index-bcg{
        .g68 {
            .element-position(146px, 137px, 344px, 60px);
            .background-img-max(url('@{assetsUrlV2_0}/index/g68_new.png')) !important;
        }
        .g69 {
            .element-position(161px, 117px, 452px, 153px);
            .background-img-max(url('@{assetsUrlMind}/img/home/g69.png')) !important;
        }
    }

    .com-item-user{
        .user-balance-box {
            .balance-icon {
                .background-img-max(url('@{assetsUrlMind}/img/home/balance-icon.png')) !important;
            }
        }
    }
    .user-box-fx{
        position: relative;
            padding-right: 19px;
    }
    // 商城入口
    .mall-enter{
        .background-img-max(url('@{assetsUrlMind}/img/mall/p1.png'));
        width: 44px;
        height: 46px;
        position: absolute;
        top: -7px;
        left: -70px;
    }
}
</style>